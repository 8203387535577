import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import RotateLoader from 'react-spinners/RotateLoader';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const OrderStageAutocomplete = ({
  value,
  options,
  loading,
  error,
  helperText,
  onChange,
  open,
  setOpen,
  submitLoad,
}) => {
  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      sx={{ width: 300 }}
      open={open}
      multiple
      disableCloseOnSelect
      disabled={submitLoad}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => option.orderCode + '- Stage: ' + option.stage}
      // getOptionSelected={(option, value) => option._id === value._id}
      options={options}
      loading={loading}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {/* <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            disabled={true}
            sx={{
                "&.Mui-disabled": {
                  color: "#2196f3"
                }
              }}
        /> */}
          {String(option.orderCode + '- Stage: ' + option.stage)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          type="CalendarChooseOrder"
          error={error}
          helperText={helperText}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <RotateLoader color="black" size={5} margin={-19} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default OrderStageAutocomplete;
