import MuiButton from '@mui/material/Button';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import { createTheme, ThemeProvider } from '@mui/material';
import { Tooltip } from '@mui/material';

const buttonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 400,
          borderRadius: 6,
          color: '#FFFFFF',
          fontSize: 18,
          padding: '0 15px',
          transition: '0.5s',
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: '#7BB9EC',
            border: '1px solid #008AFC',
            '&:hover': {
              backgroundColor: '#7BB9EC',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: '#003057',
            border: '1px solid #003057',
            '&:hover': {
              backgroundColor: '#003057',
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            '&:hover': {
              opacity: 0.65,
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: '#B3B3B3',
            padding: '0 15px',
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            padding: '0 15px',
            color: '#003057',
            borderColor: '#003057',
          },
        },
        {
          props: { variant: 'accent', color: 'primary' },
          style: {
            padding: '0 15px',
            border: '1px solid #008AFC',
            backgroundColor: '#008AFC',
            transition: '0.5s',
            '&:hover': {
              backgroundColor: '#008AFC',
              opacity: 0.65,
            },
          },
        },
        {
          props: { variant: 'accent', color: 'secondary' },
          style: {
            backgroundColor: '#B3B3B3',
            borderColor: '#B3B3B3',
            '&:hover': {
              backgroundColor: '#B3B3B3',
              opacity: 0.65,
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            width: '100%',
          },
        },
      ],
    },
  },
});

export const Button = ({ children, ...props }) => (
  <ThemeProvider theme={buttonTheme}>
    <MuiButton {...props}>{children}</MuiButton>
  </ThemeProvider>
);

export const LoadingButton = ({ children, ...props }) => (
  <ThemeProvider theme={buttonTheme}>
    <MuiLoadingButton {...props}>{children}</MuiLoadingButton>
  </ThemeProvider>
);

export const DisableableButton = ({
  children,
  disabled,
  tooltip,
  ...props
}) => (
  <ThemeProvider theme={buttonTheme}>
    {disabled ? (
      <Tooltip title={tooltip}>
        <span>
          <MuiButton disabled={disabled} {...props}>
            {children}
          </MuiButton>
        </span>
      </Tooltip>
    ) : (
      <MuiButton {...props}>{children}</MuiButton>
    )}
  </ThemeProvider>
);
