import { memo } from 'react';
import { isEqual } from 'lodash';
import { FormikProvider, Field } from 'formik';
import { Grid } from '@mui/material';

const FragmentRowErrors = memo(
  ({
    index: rowIndex,
    data: rowData,
    isNumeric,
    showAllErrors,
    submitShowError,
    errorName,
    errorVol,
    formik,
  }) => {
    return (
      <FormikProvider value={formik}>
        <Grid item xs={12} style={{ marginTop: 0 }}>
          <Field name={`fragments.${rowIndex}`}>
            {({ field, form: { touched, errors }, meta }) =>
              meta.error != undefined ? (
                <ul
                  style={{
                    listStyleType: 'disc',
                    margin: 0,
                    verticalAlign: 'middle',
                    color: '#d8000c',
                    backgroundColor: '#ffd2d2',
                  }}
                >
                  {(rowData.touched[0] || submitShowError) && showAllErrors ? (
                    <li>
                      {meta.error.sampleName ? (
                        <>● {meta.error.sampleName}</>
                      ) : (
                        <></>
                      )}
                    </li>
                  ) : (
                    <></>
                  )}

                  {(rowData.touched[1] || submitShowError) && showAllErrors ? (
                    <li>{meta.error.vol ? <>● {meta.error.vol}</> : <></>}</li>
                  ) : (
                    <></>
                  )}

                  {(rowData.touched[2] || submitShowError) && showAllErrors ? (
                    <li>
                      {meta.error.sampleType ? (
                        <>● {meta.error.sampleType}</>
                      ) : (
                        <></>
                      )}
                    </li>
                  ) : (
                    <></>
                  )}

                  {(rowData.touched[3] || submitShowError) && showAllErrors ? (
                    <li>
                      {meta.error.sizeStandard ? (
                        <>● {meta.error.sizeStandard}</>
                      ) : (
                        <></>
                      )}
                    </li>
                  ) : (
                    <></>
                  )}

                  {(rowData.touched[4] || submitShowError) && showAllErrors ? (
                    <li>
                      {meta.error.dyeSet ? <>● {meta.error.dyeSet}</> : <></>}
                    </li>
                  ) : (
                    <></>
                  )}

                  {(rowData.touched[5] || submitShowError) && showAllErrors ? (
                    <li>
                      {meta.error.runModule ? (
                        <>● {meta.error.runModule}</>
                      ) : (
                        <></>
                      )}
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              ) : (
                <></>
              )
            }
          </Field>
        </Grid>
      </FormikProvider>
    );
  },
  (prevProps, nextProps) => {
    let oldIndex = prevProps.index;
    let newIndex = nextProps.index;
    if (
      nextProps.formik.errors?.fragments?.[oldIndex] !==
      prevProps.formik.errors?.fragments?.[newIndex]
    ) {
      return false; // re-render
    }
    return (
      prevProps.id === nextProps.id &&
      isEqual(prevProps.data, nextProps.data) &&
      // && prevProps.data.touched[0] === nextProps.data.touched[0]
      prevProps.showAllErrors === nextProps.showAllErrors
    );
  }
);

export default FragmentRowErrors;
