export const SangerSampleTableHeadings = () => {
  const headingStyle = {
    color: '#003057',
    padding: '0 16px',
    borderLeft: '1px solid #0030572E',
    borderRight: '1px solid #0030572E',
  };

  return (
    <thead>
      <tr>
        <th style={{ ...headingStyle, width: 30 }}>Well</th>
        <th style={headingStyle}>Name</th>
        <th style={{ ...headingStyle, width: 150 }}>Primers</th>
        <th style={{ ...headingStyle, width: 40 }}>PM</th>
        <th style={{ ...headingStyle, width: 120 }}>Conc</th>
        <th style={{ ...headingStyle, width: 120 }}>Read Len</th>
        <th style={{ ...headingStyle, width: 80 }}>Vol</th>
        <th style={headingStyle}>Type</th>
        <th style={headingStyle}>PCR Product</th>
      </tr>
    </thead>
  );
};
