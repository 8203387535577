import TextField from '@mui/material/TextField';
import { Autocomplete, Grid } from '@mui/material';
import { memo } from 'react';

const FragmentSizeStandard = memo(({ data, updateFragment, id }) => {
  const sizeStandardOptions = [
    'Geneflo 625 ROX',
    'GS1200_LIZ_(60-1000)',
    'GS1200_LIZ_(60-860)',
    'GS1200LIZ',
    'GS120LIZ',
    'GS500(-250)LIZ',
    'GS500ROX',
    'GS600_LIZ_(60-460)',
    'GS600_LIZ_(80-400)',
    'GS600LIZ',
    'GS600_LIZ(60-600)',
    'MGfragments',
    'ROX 10 and 50',
  ];

  const handleChange = (newData, id) => {
    // const tempData = { ...data, ...newData };
    console.log(newData);
    updateFragment(newData, id);
  };

  return (
    <Grid item alignItems="stretch" style={{ display: 'flex', width: 200 }}>
      <Autocomplete
        disablePortal
        disableClearable={true}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        id="sizeStandard"
        variant="outlined"
        options={sizeStandardOptions}
        value={data.sizeStandard}
        onBlur={(e) => {
          var newTouched = [...data.touched];
          newTouched[3] = true;
          handleChange({ touched: newTouched }, id);
        }}
        onChange={async (e, currData) => {
          var newTouched = [...data.touched];
          newTouched[3] = true;
          handleChange({ sizeStandard: currData, touched: newTouched }, id);
        }}
        sx={{
          width: 240,
          '& .MuiInputBase-root': { height: 40, borderRadius: 0 },
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Size Standard"
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              style: { marginTop: -5, marginLeft: -10, marginRight: -10 },
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              fontSize: 12,
              marginLeft: -12,
              marginRight: -12,
            }}
          >
            {option}
          </li>
        )}
      />
    </Grid>
  );
});

export default FragmentSizeStandard;
