import { Button, TextField, Divider, Box } from '@mui/material';
import React, { useState } from 'react';
import { createColorCategory } from 'utils/api/Calendar';

const CustomColorForm = ({ setDisplayModalCustomColor }) => {
  // Variables and States used throughout this file
  const eventColorArray = [
    '#FF4040',
    '#FF9F40',
    '#40FF40',
    '#409FFF',
    '#FF40FF',
  ];
  const [hexCode, setHexCode] = useState('FFFFFF');
  const [rCode, setRCode] = useState('255');
  const [gCode, setGCode] = useState('255');
  const [bCode, setBCode] = useState('255');
  const [chosenColor, setChosenColor] = useState();
  const [category, setCategory] = useState();

  // Handeling methods
  const handleCreateColor = async (event) => {
    const newColorCategory = {
      color: chosenColor,
      category: category,
    };
    const res = await createColorCategory(newColorCategory);
    if (res.status === 200) {
      alert('Create New Color-Category Successfully!');
      setDisplayModalCustomColor(false);
    } else {
      alert('Error in creating a new Color-Category!');
    }
  };

  const handleChooseColor = (colorCode) => {
    setChosenColor(colorCode);
  };

  // Start rendering...
  return (
    <form autoComplete="off">
      <h2>Pick Preset Color</h2>
      <h4 style={{ marginBottom: 30 }}>
        Click on a color box to choose a color
      </h4>
      <Divider
        component="li"
        style={{
          marginBottom: 15,
          borderBottomWidth: 3,
          borderBottomColor: 'gray',
        }}
      />
      <h6 style={{ marginBottom: 15 }}>Choose one of those default colors:</h6>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          marginBottom: 30,
        }}
      >
        {eventColorArray.map((value, index) => {
          return (
            <div
              key={index}
              onClick={() => handleChooseColor(value)}
              style={{ cursor: 'pointer' }}
            >
              <Box
                sx={{
                  width: 35,
                  height: 35,
                  borderRadius: 1,
                  border: '1px solid black',
                  bgcolor: value,
                }}
              />
            </div>
          );
        })}
      </div>
      <h6 style={{ marginBottom: 15 }}>
        Choose a color based on your hex code (don't include '#'):
      </h6>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        }}
      >
        <TextField
          label="Hex Code"
          onChange={(e) => setHexCode(e.target.value)}
          variant="outlined"
          color="secondary"
          sx={{ mb: 3, width: '50%' }}
          type="Title"
          fullWidth
          value={hexCode || ''}
        />
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => handleChooseColor('#' + hexCode)}
        >
          <Box
            sx={{
              width: 50,
              height: 50,
              borderRadius: 1,
              border: '1px solid black',
              bgcolor: '#' + hexCode,
              mb: 3,
            }}
          />
        </div>
      </div>
      <h6 style={{ marginBottom: 15 }}>
        Choose a color based on your RGB code (numbers only):
      </h6>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          marginBottom: 15,
        }}
      >
        <TextField
          label="R"
          onChange={(e) => setRCode(e.target.value)}
          variant="outlined"
          color="secondary"
          sx={{ mb: 3, width: '20%' }}
          type="Title"
          fullWidth
          value={rCode || ''}
        />
        <TextField
          label="G"
          onChange={(e) => setGCode(e.target.value)}
          variant="outlined"
          color="secondary"
          sx={{ mb: 3, width: '20%' }}
          type="Title"
          fullWidth
          value={gCode || ''}
        />
        <TextField
          label="B"
          onChange={(e) => setBCode(e.target.value)}
          variant="outlined"
          color="secondary"
          sx={{ mb: 3, width: '20%' }}
          type="Title"
          fullWidth
          value={bCode || ''}
        />
        <div
          style={{ cursor: 'pointer' }}
          onClick={() =>
            handleChooseColor('rgb(' + rCode + ',' + gCode + ',' + bCode + ')')
          }
        >
          <Box
            sx={{
              width: 50,
              height: 50,
              borderRadius: 1,
              border: '1px solid black',
              bgcolor: 'rgb(' + rCode + ',' + gCode + ',' + bCode + ')',
              mb: 3,
            }}
          />
        </div>
      </div>
      <Divider
        component="li"
        style={{
          marginBottom: 15,
          borderBottomWidth: 3,
          borderBottomColor: 'gray',
        }}
      />
      <h6>Chosen Color Code: </h6>
      <div style={{ marginBottom: 15 }}>
        {chosenColor ? chosenColor : 'No color has been chosen...'}
      </div>
      <h6>Choose a Category for this Color: </h6>
      <TextField
        label="Category"
        onChange={(e) => setCategory(e.target.value)}
        variant="outlined"
        color="secondary"
        sx={{ mb: 3, width: '100%' }}
        type="Title"
        fullWidth
        value={category || ''}
      />
      <Button
        variant="contained"
        onClick={handleCreateColor}
        style={{ backgroundColor: '#008AFC', color: 'white', marginBottom: 15 }}
      >
        Save Color & Category
      </Button>
    </form>
  );
};

export default CustomColorForm;
