import { memo } from 'react';
import TextField from '@mui/material/TextField';

const SangerRowWell = memo(
  ({ index }) => {
    const rowLengthX = Math.floor(index / 8);
    const rowLengthY = index % 8;
    var startX = rowLengthX + 1;
    let sX = startX.toString();
    let sY = String.fromCharCode(rowLengthY + 65);
    const well = sY + sX;

    return (
      <TextField
        variant="outlined"
        disabled={true}
        value={well}
        sx={{
          width: 50,
          '& .MuiInputBase-root': { height: 40, borderRadius: 0 },
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '& .MuiInputBase-input': {
            textAlign: 'center',
          },
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    return prevProps.index === nextProps.index;
  }
);

export default SangerRowWell;
