import { memo } from 'react';
import TextField from '@mui/material/TextField';
import { DebounceInput } from 'react-debounce-input';
import InputAdornment from '@mui/material/InputAdornment';
import { Grid } from '@mui/material';

const FragmentRowVol = memo(
  ({ data, updateFragment, submitShowError, errorVol, id }) => {
    return (
      <Grid item>
        <Grid item alignItems="stretch" style={{ display: 'flex', width: 65 }}>
          <DebounceInput
            debounceTimeout={500}
            minLength={1}
            element={TextField}
            id="vol"
            placeholder={'Vol'}
            variant="outlined"
            value={data.vol}
            multiline
            sx={{
              width: 300,
              '& .MuiInputBase-root': {
                minHeight: 40,
                borderRadius: 0,
                width: 65,
              },
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiInputBase-input': {
                marginTop: -2,
                marginBottom: -2,
              },
            }}
            InputProps={{
              inputmode: 'numeric',
              pattern: '[0-9]*',
              endAdornment: (
                <InputAdornment
                  style={{ size: 10, marginLeft: -2, marginRight: -10 }}
                  position="end"
                >
                  µl
                </InputAdornment>
              ),
            }}
            onBlur={(e) => {
              var newTouched = [...data.touched];
              newTouched[1] = true;
              updateFragment({ touched: newTouched }, id);
            }}
            onChange={async (e) => {
              // var valInput = e.target.value !== undefined ? e.target.value : "0";
              // var val = strReturnOnlyNumbersPositive(valInput);
              var newTouched = [...data.touched];
              newTouched[1] = true;
              updateFragment(
                {
                  vol: e.target.value,
                  touched: newTouched,
                },
                id
              );
            }}
            error={submitShowError && errorVol(data.vol)}
          />
        </Grid>
      </Grid>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id && prevProps.data.vol === nextProps.data.vol
    );
  }
);

export default FragmentRowVol;
