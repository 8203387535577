export const AdminMenuItems = [
  {
    title: 'Billing',
    path: '/Billing',
    cName: 'dropdown-link',
  },
  {
    title: 'Inventory',
    path: '/Inventory',
    cName: 'dropdown-link',
  },
  {
    title: 'Executive',
    path: '/ExecutiveCommands/0',
    cName: 'dropdown-link',
  },
  {
    title: 'Calendar',
    path: '/Calendar',
    cName: 'dropdown-link',
  },
];

export const HomeMenuItems = [
  {
    title: 'About Us',
    path: '/Aboutus',
    cName: 'dropdown-link',
  },
  {
    title: 'Meet the Team',
    path: '/Team',
    cName: 'dropdown-link',
  },
  {
    title: 'Pricing',
    path: '/Pricing',
    cName: 'dropdown-link',
  },
];
