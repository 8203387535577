import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

export const getOrdersForCalendar = async () => {
  try {
    const res = await axios.get(API_ENDPOINT + '/order/ordersForCalendar');
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllOrders = async () => {
  try {
    const res = await axios.get(API_ENDPOINT + '/order/allOrders');
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCalendarEvents = async () => {
  try {
    const res = await axios.get(API_ENDPOINT + '/calendar');
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllUsers = async () => {
  try {
    const res = await axios.get(API_ENDPOINT + '/user/all');
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCalendarPresets = async () => {
  try {
    const res = await axios.get(API_ENDPOINT + '/calendar/preset');
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getColorCategory = async () => {
  try {
    const res = await axios.get(API_ENDPOINT + '/calendar/color');
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const createCalendarEvent = async (event) => {
  try {
    const res = await axios.post(API_ENDPOINT + '/calendar', event);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const createCalendarPreset = async (event) => {
  try {
    const res = await axios.post(API_ENDPOINT + '/calendar/preset', event);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const createColorCategory = async (event) => {
  try {
    const res = await axios.post(API_ENDPOINT + '/calendar/color', event);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const cancelCalendarEvent = async (event) => {
  try {
    const res = await axios.patch(API_ENDPOINT + `/calendar/cancel`, event);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateCalendarEvent = async (event) => {
  try {
    const res = await axios.patch(API_ENDPOINT + `/calendar`, event);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateCalendarEventTaskStatus = async (event) => {
  try {
    const res = await axios.patch(API_ENDPOINT + `/calendar/taskStatus`, event);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateCalendarPreset = async (event) => {
  try {
    const res = await axios.patch(API_ENDPOINT + `/calendar/editPreset`, event);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCalendarEvent = async (event) => {
  try {
    const res = await axios.delete(API_ENDPOINT + `/calendar/delete`, {
      data: event,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
