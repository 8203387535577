import { useContext, useEffect, useState } from 'react';
import 'features/FAQ/FaqComponent.css';
import { PHONE_WIDTH } from 'lib/ScreenSizes';

import Trail from './components/TitleTrail';
import HomeInstruct from './components/HomeInstruct';
import Locations from 'reusable/Locations/Locations';
import { Nav as Navs } from 'react-bootstrap';
import AuthContext from 'utils/auth/auth';
import { NavLink } from 'reusable/Navbar/NavBarElements';
import { useWindowSize } from 'hooks/useWindowSize';

import { useInView } from 'react-intersection-observer';

import backgroundDesktopThumb from 'videos/desktopDnaThumb.jpeg';
import phoneDnaThumb from 'images/HomePage/phoneDnaThumb.jpg';

import backgroundVideo from 'videos/dnaCompressed.mp4';
import backgroundVideoPhone from 'videos/newTests/phoneCut.mp4';
import { downloadExcel } from 'utils/api/ExcelForm';

const HomePage = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [width, height] = useWindowSize();
  const user = useContext(AuthContext);
  const [amtScrolledY, setAmtScrolledY] = useState(0.9);

  const [titleOneRef, inViewTitleOne] = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 1500,
  });
  const titleOneViewed = inViewTitleOne ? 'left' : 'right';

  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  /**
   * Keeps track of the amount scrolled on the y-axis in order to change the opacity of the overlay.
   */
  const handleScroll = () => {
    const scrollY = window.scrollY;

    setAmtScrolledY((0.9 * (500 - scrollY)) / 500);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isPhone = width < PHONE_WIDTH;
  const phoneTitleScale = 'scale(1)';
  const phoneTitleFontSize = isPhone ? '55px' : '79px';
  const phoneTitleScaleWrap = isPhone ? '' : 'nowrap';

  const blueTextColor = 'rgb(0, 48, 87)';
  // const lightBlueTextColor = 'rgb(0, 138, 252)';
  const instructions = [
    {
      title: 'Upload Form',
      text: (
        <div className="instruct--spaced">
          Log in to place an order.
          <br></br>
          <item className="instruct__button">
            <button onClick={() => downloadFile(1)}>Sanger Sequencing</button>
          </item>
          <item className="instruct__button">
            <button onClick={() => downloadFile(0)}>Fragment Analysis</button>
          </item>
        </div>
      ),
      footer: <>Save your drop-off code.</>,
    },
    {
      title: 'Drop Off Samples',
      text: (
        <>
          Submit your samples. Include your contact info and unique code with
          your samples. Drop off at one of the 6 locations listed below.
        </>
      ),
      footer: <>Scan the QR code at a drop-box and input your code.</>,
    },
    {
      title: 'Get Results',
      text: (
        <>
          Your results will be available through your account's inbox in 0* to 2
          business days.
          <br></br>
          <div className="instruct__note">
            *First 24 samples submitted before 9:30 AM can be processed same-day
          </div>
        </>
      ),
      footer: (
        <item className="instruct__button">
          {!user || user.casUser === 'Not authenticated' ? (
            <Navs.Link
              href={`/cas/authenticate?returnTo=${window.location.pathname}`}
            >
              Sign In
            </Navs.Link>
          ) : (
            <NavLink to="/Profile">Sign In</NavLink>
          )}
        </item>
      ),
    },
  ];

  const downloadFile = async (fileNo) => {
    const res = await downloadExcel(fileNo);
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    var fileName;
    if (fileNo === 1) {
      fileName = 'Sanger-Sequencing-form.xlsx';
    } else {
      fileName = 'Fragment-Analysis-form.xlsx';
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="home__container">
      <div
        className="containerHome"
        style={{
          maxheight: isPhone ? 100 : null,
          minHeight: isPhone ? 654 : '',
        }}
      >
        <img
          src={width > PHONE_WIDTH ? backgroundDesktopThumb : phoneDnaThumb}
          className="video-thumb tinyThumb"
          alt="thumb"
          width="100%"
          style={{ opacity: isVideoLoaded ? 0 : 1 }}
        />
        <video autoPlay loop playsInline id="video" width={'100%'}>
          <source
            src={isPhone ? backgroundVideoPhone : backgroundVideo}
            onLoadedData={onLoadedData}
            type="video/mp4"
            style={{ opacity: isVideoLoaded ? 1 : 0 }}
          ></source>
        </video>
        <div
          className="overlayHome"
          style={{
            backgroundColor: 'white',
            width: isPhone ? '100%' : '40%',
            textAlign: 'center',
            opacity: amtScrolledY,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',
              fontSize: '58px',
              fontWeight: 800,
              color: blueTextColor,
            }}
          >
            <Trail open={amtScrolledY <= 300}>
              Easy.
              <br></br>
              Convenient.
              <br></br>
              Fast.
            </Trail>
          </div>
        </div>
      </div>
      <div
        ref={titleOneRef}
        style={{ position: 'relative', transform: phoneTitleScale }}
      >
        <div
          className="TextColorSlide"
          style={{
            backgroundPosition: titleOneViewed,
            transitionDuration: '5s',
            height: 'auto',
            textAlign: 'center',
            fontSize: phoneTitleFontSize,
            fontWeight: 'bold',
            color: blueTextColor,
            marginTop: 35,
            marginBottom: 35,
            whiteSpace: phoneTitleScaleWrap,
          }}
        >
          <text
            style={{
              WebkitTextFillColor: 'transparent',
              whiteSpace: phoneTitleScaleWrap,
              marginRight: 20,
            }}
          >
            Easy
          </text>
          <text style={{ whiteSpace: phoneTitleScaleWrap }}>
            Sample Submission
          </text>
        </div>
      </div>
      <HomeInstruct items={instructions} />
      <div className="home__locations">
        <Locations screenWidth={width} backgroundColor={'#eeeeeeff'} />
      </div>
    </div>
  );
};

export default HomePage;
