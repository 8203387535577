import { memo } from 'react';
import TextField from '@mui/material/TextField';
import { DebounceInput } from 'react-debounce-input';
import { Grid } from '@mui/material';

const fragmentRowName = memo(
  ({ data, submitShowError, updateFragment, errorName, id }) => {
    return (
      <Grid item>
        <DebounceInput
          debounceTimeout={500}
          minLength={1}
          element={TextField}
          required
          multiline
          id="sample-name"
          variant="outlined"
          sx={{
            '& .MuiInputBase-root': {
              width: 180,
              minHeight: 40,
              borderRadius: 0,
              borderColor: 'black',
            },
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            '& .MuiInputBase-input': {
              marginTop: -2,
              marginBottom: -2,
            },
          }}
          inputProps={{ spellCheck: false }}
          label={undefined}
          placeholder="Sample Name"
          value={data.sampleName}
          onBlur={(e) => {
            var newTouched = [...data.touched];
            newTouched[0] = true;
            updateFragment({ touched: newTouched }, id);
          }}
          onChange={(e) => {
            var newTouched = [...data.touched];
            newTouched[0] = true;
            updateFragment(
              { sampleName: e.target.value, touched: newTouched },
              id
            );
          }}
          error={submitShowError && errorName(data.sampleName)}
        />
      </Grid>
    );
  }
);

export default fragmentRowName;
