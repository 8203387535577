import { createTheme, ThemeProvider } from '@mui/material';
import MuiDivider from '@mui/material/Divider';

const dividerTheme = createTheme({
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          border: 'solid 2.5px #7BB9EC',
          margin: 'auto',
          opacity: 1,
        },
      },
      variants: [
        {
          props: { variant: 'short' },
          style: {
            width: '70%',
          },
        },
      ],
    },
  },
});

export const Divider = ({ children, ...props }) => (
  <ThemeProvider theme={dividerTheme}>
    <MuiDivider />
  </ThemeProvider>
);
