import React, { useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Field, Form, Formik } from 'formik';
import {
  PopupGridItem,
  PopupBillingCycleYear,
  PopupNotes,
  PopupGridItemWithComponent,
  PopupPCRBlock,
  PopupGridDateReceived,
  PopupSSTimer,
  PopupUploadResults,
  PopupOrdersInPlate,
  PopupGridItemLine,
  PopupGridTwoItems,
  PopupBillingCycleSemester,
  PopupPcrMachineTimer,
} from './PopupImports';
import AuthContext from 'utils/auth/auth';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import { socket } from 'context/socket';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import NewTabButton from '../NewTabButton';
import SampleListView from '../List/SampleListView';
import SampleListViewCharged from '../List/SampleListViewCharged';
import SampleListViewRerun from '../List/SampleListViewRerun';
import getOrderCode from 'utils/orderCode';
import { updateOrder, updateOrderStage } from 'utils/api/Orders';
import TextField from '@mui/material/TextField';

// In your component or page:

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function PopupContent({
  order,
  sampleMap,
  ordersInCR = [],
  handleClose,
  viewOnly,
  setOrderReceived,
  workflow,
}) {
  const user = useContext(AuthContext);
  const isComboRun = order.isComboRun;
  const billingCycleSemester = isComboRun
    ? ''
    : order.billingCycle.split(' ')[0];
  const billingCycleYear = isComboRun
    ? ''
    : (parseInt(order.billingCycle.split(' ')[1]) + 1).toString();
  const [submitLoad, setSubmitLoad] = useState(false);
  const [saveLoad, setSaveLoad] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const [noEmail, setNoEmail] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const PCRMachineTimerDate =
    Date.now() +
    (7500000 - (Date.now() - new Date(order.pcrMachineTimer).getTime()));
  const SSMachineTimerDate = new Date(order.ssTimer).getTime();
  const adminOnly =
    (order.stage === 'SS Machine' || order.stage === 'Resulting') &&
    !user.admin;
  const disableForCR =
    (order.stage === 'SS Machine' || order.stage === 'Ready to load') &&
    order.inComboRun;
  var ordersSampleLocation = [];
  var object = new Object();
  object['orderCode'] = order.orderCode;
  ordersSampleLocation[0] = object;

  // instrument properties
  console.log(sampleMap);
  const instrument = sampleMap
    ? sampleMap.instrument ?? 'SeqStudio (unset)'
    : '';
  const cartridge =
    sampleMap && sampleMap.cartridge
      ? sampleMap.cartridge.letter ?? 'Unassigned'
      : '';
  const block = sampleMap ? sampleMap.block ?? 'Unassigned' : '';

  const handleSave = async (values) => {
    setSaveLoad(true);
    if (values.controls) {
      var controlArray = values.controls;
      for (var i = controlArray.length - 1; i >= 0; i--) {
        if (controlArray[i] == '') {
          controlArray.splice(i, 1);
        }
      }
      values.controls = controlArray;
    }
    if (!isComboRun) {
      values.billingCycle =
        values.billingCycleSemester +
        ' ' +
        (parseInt(values.billingCycleYear) - 1).toString();
    }
    try {
      const res = await updateOrder(
        values,
        order.orderCode,
        order.stage,
        noEmail
      );
      const timer = await sleep(1e3);
      if (res.status === 200) {
        socket.emit('saveOrder', order.orderCode);
        enqueueSnackbar(
          'Changes for ' + order.orderCode + ' sucessfully saved!',
          { variant: 'success' }
        );
        setOrderReceived(false);
      }
    } catch (err) {
      enqueueSnackbar(
        'Changes for ' + order.orderCode + ' could not be saved!',
        { variant: 'error' }
      );
    }
    setSaveLoad(false);
  };

  const orderCode = getOrderCode(order);

  return (
    <>
      <Grid container>
        <Grid item xs={11} style={{ paddingBottom: '10px' }}>
          {order.inComboRun ? (
            <h2 className="ordersPopupOrdercodeTitle" style={{ float: 'left' }}>
              {orderCode}
            </h2>
          ) : (
            <h2 className="ordersPopupOrdercodeTitle">{orderCode}</h2>
          )}
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          style={{
            maxHeight: 540,
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '560px',
          }}
          rowSpacing={0}
          spacing={0}
        >
          <Grid item xs={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                stage: order.stage,
                billingCycleSemester: billingCycleSemester,
                billingCycleYear: billingCycleYear,
                notes: order.notes,
                pcrBlock: order.pcrBlock ? order.pcrBlock : '',
                manualPcrMachineTimer: order.manualPcrMachineTimer
                  ? order.manualPcrMachineTimer
                  : false,
                pcrMachineTimer: order.pcrMachineTimer
                  ? order.pcrMachineTimer
                  : '',
                ssTimer: order.ssTimer ? order.ssTimer : '',
                controls: order.controls ? order.controls : [],
                submissionType: order.submissionType,
                sangerNoOfSamples: order.sangerNoOfSamples,
                fragmentNoOfSamples: order.fragmentNoOfSamples,
                noOfSamples: order.noOfSamples,
                hasResultFile: false,
              }}
              validationSchema={Yup.object({
                stage: Yup.string(),
                manualPcrMachineTimer: Yup.boolean(),
                Notes: Yup.string().max(550, 'Max is 550 characters'),
                pcrBlock: Yup.string().when('stage', {
                  is: (stage) =>
                    (stage === 'PCR Prep' ||
                      stage === 'PCR Machine' ||
                      stage === 'Purification' ||
                      stage === 'Ready to load' ||
                      stage === 'SS Machine' ||
                      stage === 'Resulting') &&
                    order.submissionType != 'fragment',
                  then: Yup.string().required('Required'),
                }),
                ssTimer: Yup.date().when('stage', {
                  is: (stage) =>
                    stage === 'Ready to load' || stage === 'SS Machine',
                  then: Yup.date().required('Required'),
                }),
                pcrMachineTimer: Yup.date().when('manualPcrMachineTimer', {
                  is: (manualPcrMachineTimer) =>
                    manualPcrMachineTimer === true &&
                    order.submissionType != 'fragment',
                  then: Yup.date()
                    .required('Required')
                    .max(Date(), 'Cannot be in the future!'),
                }),
                controls: Yup.array().of(
                  Yup.string()
                    .max(30, 'Max 30 characters')
                    .min(1, 'Required 32')
                    .required('Required')
                ),
                noFile: Yup.boolean(),
                hasResultFile: Yup.boolean().when(['stage', 'noFile'], {
                  is: (stage, noFile) => !noFile && stage === 'Resulting',
                  then: Yup.boolean().isTrue('Must upload a results zip file'),
                }),
              })}
              onSubmit={async (values) => {
                if (!isComboRun) {
                  values.billingCycle =
                    values.billingCycleSemester +
                    ' ' +
                    (parseInt(values.billingCycleYear) - 1).toString();
                }
                setSubmitLoad(true);
                try {
                  const res = await updateOrderStage(
                    values,
                    order.orderCode,
                    order.stage,
                    noEmail
                  );
                  if (res.status === 200) {
                    socket.emit('submitOrder', order.orderCode);
                    switch (order.stage) {
                      case 'pickup':
                        enqueueSnackbar(
                          order.orderCode + ' has been picked up',
                          {
                            variant: 'success',
                          }
                        );
                        break;
                      case 'PCR Prep':
                        enqueueSnackbar(
                          order.orderCode + ' has completed PCR Prep!',
                          {
                            variant: 'success',
                          }
                        );
                        break;
                      case 'PCR Machine':
                        enqueueSnackbar(
                          order.orderCode + ' has completed PCR Machine!',
                          {
                            variant: 'success',
                          }
                        );
                        break;
                      case 'Purification':
                        enqueueSnackbar(
                          order.orderCode + ' has completed Purification',
                          {
                            variant: 'success',
                          }
                        );
                        break;
                      case 'Ready to load':
                        enqueueSnackbar(order.orderCode + ' has been loaded!', {
                          variant: 'success',
                        });
                        break;
                      case 'SS Machine':
                        enqueueSnackbar(
                          order.orderCode + ' has completed SS Machine!',
                          {
                            variant: 'success',
                          }
                        );
                        break;
                      default:
                        enqueueSnackbar(
                          order.orderCode + ' resulting complete!',
                          {
                            variant: 'success',
                          }
                        );
                        break;
                    }
                  }
                } catch (err) {
                  enqueueSnackbar(
                    order.orderCode +
                      ' could not submitted! Please contact adminsitrator!',
                    { variant: 'error' }
                  );
                }
                setSubmitLoad(false);
                handleClose();
              }}
            >
              {(formikProps) => (
                <Form>
                  <PopupGridItem attribute={'Stage'} data={order.stage} />
                  {!isComboRun ? (
                    <>
                      <PopupGridItem
                        attribute={'Location'}
                        data={order.dropLocation}
                      />
                      {order.stage != 'Storage' ? (
                        <PopupGridItem
                          attribute={'Drop-Off Code'}
                          data={order.dropOffCode}
                        />
                      ) : (
                        <></>
                      )}
                      <PopupGridItem
                        attribute={'Lab Name'}
                        data={order.labName}
                      />
                      <PopupGridItem
                        attribute={'Researcher Name'}
                        data={order.researcherName}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <PopupGridItem
                    attribute={'Submission Type'}
                    data={
                      order.submissionType.charAt(0).toUpperCase() +
                      order.submissionType.slice(1)
                    }
                  />
                  <PopupGridItem
                    attribute={'No. of Samples'}
                    data={order.noOfSamples}
                  />
                  <PopupGridItem attribute={'Instrument'} data={instrument} />
                  {instrument === 'SeqStudio Flex' ? (
                    <PopupGridItem attribute={'Block'} data={block} />
                  ) : (
                    <PopupGridItem attribute={'Cartridge'} data={cartridge} />
                  )}
                  {!isComboRun ? (
                    <>
                      <PopupGridDateReceived order={order} />
                      <PopupGridItemLine
                        attribute={'Sample List'}
                        data={
                          <SampleListView
                            orderCode={order.orderCode}
                            mergeSameWellNumber={true}
                            orderRerunType={order.rerunType}
                          />
                        }
                      />
                      <PopupGridItemLine
                        attribute={'Sample Map'}
                        data={
                          <NewTabButton
                            to={`/samplemap/${order.sampleMap}`}
                            label="Sample Map"
                            variant="contained"
                            color="primary"
                          />
                        }
                      />
                      {order.stage === 'Resulting' && (
                        <PopupGridItemLine
                          attribute={'Billing'}
                          data={
                            <SampleListViewCharged
                              orderCode={order.orderCode}
                              orderRerunType={order.rerunType}
                            />
                          }
                        />
                      )}
                      {workflow ? (
                        <>
                          <PopupGridTwoItems
                            attribute={'Create Order Rerun'}
                            dataA={
                              <SampleListViewRerun
                                orderCode={order.orderCode}
                                buttonTitle={'Internal'}
                                orderRerunType={order.rerunType}
                              />
                            }
                            dataB={
                              <SampleListViewRerun
                                orderCode={order.orderCode}
                                buttonTitle={'External'}
                                orderRerunType={order.rerunType}
                              />
                            }
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <PopupGridItemLine
                        attribute={'Sample Map'}
                        data={
                          <NewTabButton
                            to={`/samplemap/${order.sampleMap}`}
                            label="Sample Map"
                            variant="contained"
                            color="primary"
                          />
                        }
                      />
                      <PopupOrdersInPlate
                        orders={ordersInCR}
                        handleClose={handleClose}
                      />
                    </>
                  )}
                  {order.stage !== 'pickup' &&
                  order.stage !== 'Arrived' &&
                  order.submissionType !== 'fragment' ? (
                    <Field
                      name="pcrBlock"
                      component={PopupPCRBlock}
                      disabled={submitLoad || saveLoad || deleteLoad}
                      showSelect={!viewOnly}
                    />
                  ) : (
                    <></>
                  )}
                  {/* Calculate the elpased time and finds the difference from 2 hours and 5 minutes
									Date.now() + (7500000 - (Date.now() - new Date(order.pcrMachineTimer).getTime()))*/}
                  {order.stage !== 'Arrived' &&
                  order.stage !== 'pickup' &&
                  order.submissionType !== 'fragment' ? (
                    <Field
                      name="pcrMachineTimer"
                      component={PopupPcrMachineTimer}
                      disabled={submitLoad || saveLoad || deleteLoad}
                      showSelect={!viewOnly}
                      date={PCRMachineTimerDate}
                      formikProps={formikProps}
                    />
                  ) : (
                    <></>
                  )}
                  {order.stage === 'Ready to load' ||
                  order.stage === 'SS Machine' ||
                  order.stage === 'Resulting' ? (
                    <>
                      <Field
                        name="ssTimer"
                        component={PopupSSTimer}
                        disabled={
                          submitLoad || saveLoad || deleteLoad || disableForCR
                        }
                        showSelect={!viewOnly}
                        date={SSMachineTimerDate}
                        formikProps={formikProps}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid container rowSpacing={0} spacing={0}>
                    {!isComboRun ? (
                      <>
                        <Grid item sm={6}>
                          <h3 className="PopupGridItem">Billing Cycle</h3>
                        </Grid>
                        <Grid item sm={3}>
                          <Field
                            name="billingCycleSemester"
                            component={PopupBillingCycleSemester}
                            disabled={submitLoad || saveLoad || deleteLoad}
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <Field
                            name="billingCycleYear"
                            component={PopupBillingCycleYear}
                            disabled={submitLoad || saveLoad || deleteLoad}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <hr
                            style={{
                              background: 'black',
                              height: '2px',
                            }}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    <PopupGridItemWithComponent
                      attribute="Notes"
                      columnA={2}
                      columnB={10}
                      data={
                        <Field
                          name="notes"
                          component={PopupNotes}
                          disabled={submitLoad || saveLoad || deleteLoad}
                        />
                      }
                    />
                    <PopupGridItemWithComponent
                      attribute={'Client Notes'}
                      columnA={2}
                      columnB={10}
                      data={
                        <TextField
                          fullWidth={true}
                          multiline
                          value={order.clientNotes ? order.clientNotes : 'None'}
                          disabled={true}
                        />
                      }
                    />
                    {order.stage === 'Resulting' ? (
                      <PopupUploadResults
                        orderCode={order.orderCode}
                        formikProps={formikProps}
                        noEmail={noEmail}
                        setNoEmail={setNoEmail}
                      />
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid container rowSpacing={0} spacing={0}>
                    <Grid item sm={6}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <LoadingButton
                          size="large"
                          classes="profileSubmit"
                          variant="outlined"
                          onClick={() => handleSave(formikProps.values)}
                          loading={saveLoad || deleteLoad}
                          disabled={submitLoad || adminOnly || viewOnly}
                          loadingIndicator={
                            <RotateLoader
                              cssOverride={{ left: '100%' }}
                              size={7}
                              margin={-15}
                            />
                          }
                        >
                          Save Changes
                        </LoadingButton>
                      </Box>
                    </Grid>
                    {disableForCR ? (
                      <></>
                    ) : (
                      <Grid item sm={6}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <LoadingButton
                            size="large"
                            classes="profileSubmit"
                            variant="outlined"
                            type="submit"
                            loading={submitLoad}
                            disabled={
                              saveLoad || deleteLoad || adminOnly || viewOnly
                            }
                            loadingIndicator={
                              <RotateLoader
                                cssOverride={{ left: '100%' }}
                                size={7}
                                margin={-15}
                              />
                            }
                          >
                            {order.stage === 'Arrived' &&
                            order.submissionType != 'fragment' ? (
                              <>Move to PCR Prep</>
                            ) : (
                              <></>
                            )}
                            {order.stage === 'Arrived' &&
                            order.submissionType === 'fragment' ? (
                              <>Ready to load</>
                            ) : (
                              <></>
                            )}
                            {order.stage === 'PCR Prep' ? (
                              <>Start PCR Machine timer</>
                            ) : (
                              <></>
                            )}
                            {order.stage === 'PCR Machine' ? (
                              <>Begin Purification</>
                            ) : (
                              <></>
                            )}
                            {order.stage === 'Purification' ? (
                              <>Ready to load</>
                            ) : (
                              <></>
                            )}
                            {order.stage === 'Ready to load' ? (
                              <>Start SS Machine timer</>
                            ) : (
                              <></>
                            )}
                            {order.stage === 'SS Machine' ? (
                              <>Move to resulting</>
                            ) : (
                              <></>
                            )}
                            {order.stage === 'Resulting' ? (
                              <>Store and complete</>
                            ) : (
                              <></>
                            )}
                          </LoadingButton>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
